
import { Component, Vue } from 'vue-property-decorator'

import UniversalCookie from 'universal-cookie'
import MoreBtn from '~/components/shared/MoreBtn.vue'
import SecondaryTitle from '~/components/shared/SecondaryTitle.vue'
import TitleWithViewAll from '~/components/shared/TitleWithViewAll.vue'
import PickupTitle from '~/components/shared/PickupTitle.vue'
import RecommendedGroup from '~/components/layouts/RecommendedGroup.vue'
import MixinKarteTracking from '~/components/mixins/MixinKarte'
import ArticleCard from '~/components/shared/card/ArticleCard.vue'
import CompanySmallCard from '~/components/shared/card/CompanySmallCard.vue'
import CarouselContents from '~/components/shared/CarouselContents.vue'
import LoadingSpinner from '~/components/shared/LoadingSpinner.vue'
import authSetting from '~/config/authSetting'
import MixinMeta from '~/components/mixins/MixinMeta'
import MixinMemberId from '~/components/mixins/MixinMemberId'
import MessageAfterLogin from '~/components/shared/MessageAfterLogin.vue'
import CookiePolicyDialog from '~/components/shared/CookiePolicyDialog.vue'
import { Meta } from '~/types/meta'
import { Companies } from '~/types/companies'
import { Stories } from '~/types/stories'

const camelcaseKeys = require('camelcase-keys')

@Component({
  components: {
    MoreBtn,
    SecondaryTitle,
    TitleWithViewAll,
    PickupTitle,
    CompanySmallCard,
    CarouselContents,
    LoadingSpinner,
    ArticleCard,
    RecommendedGroup,
    MessageAfterLogin,
    CookiePolicyDialog
  },
  mixins: [MixinKarteTracking, MixinMeta, MixinMemberId]
})
export default class Top extends Vue {
  private memberId!: number
  private showLoading: boolean = true
  private howToEnjoy: string = `自分にあったカテゴリでコンテンツを探せます。\nカテゴリを組み合わせると、さらに絞り込んでコンテンツを探せます。`
  private isOpenCookiePolicyDialog: boolean = false

  private get top(): Stories.TopAll {
    return JSON.parse(JSON.stringify(this.$store.state.stories.topContents))
  }

  private get headBase(): Meta.HeadBase {
    return {
      title: 'talentbook(タレントブック)',
      link: [
        {
          rel: 'canonical',
          href: process.env.BASE_URL + this.$route.path
        }
      ]
    }
  }

  private async beforeCreate(): Promise<void> {
    if (process.client) {
      const topUrl = process.env.API_URL + '/top-all'
      const config = {
        auth: authSetting,
        withCredentials: true
      }
      try {
        await this.$axios
          .$get(topUrl, config)
          .then((res) => {
            const resData = camelcaseKeys(res, { deep: true })
            this.$store.dispatch('stories/getTopContents', resData)
            this.$store.dispatch(
              'companies/getTopCompanies',
              resData.topCompanies
            )
          })
          .then(() => {
            this.showLoading = false
          })
      } catch (err) {
        return err
      }
    }
  }

  private categoryMixTitle(tags): string {
    return tags.map((v: any) => v.name).join(' + ')
  }

  private categoryMixLink(tags): string {
    return '/contents?category=' + tags.map((v: any) => v.id).join(',')
  }

  private get topCompanies(): Companies.SimpleCompanyDetail[] {
    return this.$store.state.companies.topCompanies
  }

  private changeFollowing(company): void {
    this.$store
      .dispatch('companies/updateCompaniesFollowing', {
        memberId: this.memberId,
        id: company.id,
        follow: company.following
      })
      .then(() => {
        this.$store.dispatch('companies/updateTopCompanyFollowing', company.id)
      })
  }

  private get sortBannersByOrder() {
    return this.top.banners.sort((a, b) => a.order - b.order)
  }

  private mounted() {
    const cookie = new UniversalCookie()
    const isReadCookiePolicy = cookie.get('read_cookie_policy')
    // cookie policyダイアログの表示制御
    this.isOpenCookiePolicyDialog = !isReadCookiePolicy
  }
}
